import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import de from 'dayjs/locale/de'
import updateLocale from 'dayjs/plugin/updateLocale'
import duration from 'dayjs/plugin/duration'
// import unix from 'dayjs/plugin/unix'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.locale(de)
dayjs.extend(updateLocale)
dayjs.extend(duration)
// dayjs.extend(unix)
dayjs.updateLocale('de', {
    monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
})
Object.defineProperty(Vue.prototype, '$dayjs', {value: dayjs})