import Vue from 'vue'
import Vuex from 'vuex'
import vapor from "@/plugins/vapor";
import avTechnicianCompleteVodUpload from "@/graphql/mutations/avTechnicianCompleteVodUpload.graphql";
import _map from "lodash/map";

const beforeUnloadListener = (event) => {
    event.preventDefault();
    return event.returnValue = "Are you sure you want to exit?";
};

Vue.use(Vuex)

const videoUploads = {
    namespaced: true,
    state: () => ({
        list: {},
        refetchQuery: null,
    }),
    mutations: {
        addUpload(state, payload) {
            Vue.set(state.list, payload.id, {
                id: payload.id,
                name: payload.name,
                progress: 0
            })
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
            addEventListener("beforeunload", beforeUnloadListener, {capture: true});
        },
        setRefetchQuery(state, query) {
            state.refetchQuery = query
        },
        updateProgress(state, payload) {
            state.list[payload.id].progress = payload.progress
        },
        removeUpload(state, id) {
            Vue.delete(state.list, id)
            removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
        }
    },
    actions: {
        async start({state, dispatch, commit}, payload) {
            commit('addUpload', {
                id: payload.vodId,
                name: payload.vodName,
                progress: 0
            })
            let res = await vapor.storeMultipart(payload.video, require(`@/graphql/mutations/avTechnicianInitVodUpload.graphql`),
                'avTechnicianInitVodUpload',
                payload.apollo,
                (progress) => {
                    commit('updateProgress', {progress: progress, id: payload.vodId})
                })
                .catch((e) => {
                    commit('removeUpload', payload.vodId)
                    dispatch('errorAlert/serverErrors', e, {root: true})
                });
            commit('updateProgress', {progress: 0, id: payload.vodId})
            if (res && res.parts) {
                let variables = {vodId: res.vodId, uuid: res.uuid, uploadId: res.uploadId, parts: res.parts}
                payload.apollo.mutate({
                        mutation: avTechnicianCompleteVodUpload,
                        variables: variables,
                        update: () => {
                            commit('removeUpload', payload.vodId)
                            if (state.refetchQuery) {
                                state.refetchQuery.refetch()
                            }
                        },
                    },
                ).finally(() => {
                    this.loading = false;
                });
            } else {
                dispatch('errorAlert/serverErrors', {}, {root: true})
                this.loading = false
                return;
            }

        },
    }
}

const successAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        text: '',
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setText(state, text) {
            state.text = text
        },
    },
    actions: {
        success({commit}, text = 'Erfolgreich gespeichert!') {
            commit('setText', text)
            commit('setActive', true)
            commit('errorAlert/setActive', false, {root: true})
        }
    }
}

const errorAlert = {
    namespaced: true,
    state: () => ({
        active: false,
        serverErrors: [],
    }),
    mutations: {
        setActive(state, active) {
            state.active = active
        },
        setServerErrors(state, errors) {
            state.serverErrors = errors
        },
    },
    actions: {
        serverErrors({commit}, {e, message}) {
            if (e && 'graphQLErrors' in e) {
                let messages = e.graphQLErrors[0].extensions.validation;
                if (messages) {
                    commit('setServerErrors', _map(messages, (message) => {
                        return message[0]
                    }))
                } else {
                    commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
                }
            } else if (message) {
                commit('setServerErrors', [message])
            } else {
                commit('setServerErrors', ['Es ist ein unerwarteter Fehler aufgetreten'])
            }
            commit('setActive', true)
            commit('successAlert/setActive', false, {root: true})
        }
    }
}
export default new Vuex.Store({
    modules: {
        successAlert: successAlert,
        errorAlert: errorAlert,
        videoUploads: videoUploads,
    },
})