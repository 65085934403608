import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../views/Maintenance'),
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error'),
    },
    {
        path: '',
        name: 'Livestreams',
        component: () => import('../views/Livestreams'),
    },
    {
        path: '/livestream/:id',
        name: 'Livestream',
        component: () => import('../views/Livestream'),
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
